<template>
  <!-- <Config /> -->
  <div class="top-bar">
    <!-- <div class="logo">betBOSS.ai</div> -->
    <div class="logo">
      <!-- <img src="../assets/logo.png" alt="betBOSS.ai" />
        <img src="../assets/logo2.png" alt="betBOSS.ai" />
        <img src="../assets/logo3.png" alt="betBOSS.ai" /> -->
      <div>
        <a href="https://betboss.ai">
          <img src="../assets/logo-ai.png" alt="betBOSS.ai" />
          <small class="slogan">Your best bet.</small>

        </a>
      </div>
      <!-- <div>
        <a href="#" @click.prevent="openWhyModal" class="why-link" @click="handleHowItWorksClick">
          <small style="font-size: 17px;">Why does it work?</small>
        </a>
      </div> -->

    </div>
    <div style="display: flex; flex-direction: column;">
      <div>
      </div>
      <!-- Display remaining requests -->
      <div class="rate-limit-info" style="display: flex; flex-direction: column; align-items: center;">
        <!-- <div>50 remaining.</div> -->
        <!-- <div>{{ formatResetTime(store.reset) }} mins till reset</div> -->
        <!-- <div><strong><h4>Under Maintenance</h4></strong></div> -->
        <div v-if="!store.isLoggedIn">Signup to increase limit</div>
        <!-- <div v-else>{{ store.remainingSearches }} remaining.</div> -->
        <!-- Display search count only if greater than 0 and defined -->
        <div v-if="store.searchCount > -1">Picks used: {{ store.searchCount }}/{{ store.allowedSearches }}</div>
        <div style="text-align: center;">
          <div class="user-email">{{ store.userEmail }}</div>
          <a v-if="store.isLoggedIn && store.userTier === 'free'" href="https://betboss.ai/stripe"
            style="display: inline-block; margin: 0 auto;" @click="handlePricingClick">FREE TRIAL</a>
          <template v-if="!store.isLoggedIn">
            <a href="https://back.betboss.ai/protected" style="display: inline-block; margin: 0 auto;"
              @click="handleLoginClick">LOGIN</a> |
            <a href="https://auth.betboss.ai/register" :class="{ 'bigger-signup': showProminentSignup }"
              @click="handleSignupClick">SIGNUP</a>
          </template>

          <div v-if="store.userTier === 'rookie'">Rookie</div>
          <div v-if="store.userTier === 'pro'">Pro</div>
        </div>
      </div>
    </div>
    <!-- Add the WhysThisGood component -->
    <WhysThisGood :is-open="isWhyModalOpen" @close="closeWhyModal" />
    <!-- <button @click="hydrateNow">Hydrate</button> -->
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { useStore } from "../stores/store";
import { useRouter, useRoute } from 'vue-router';
import { useGtm } from "@gtm-support/vue-gtm";
import axios from 'axios';
import WhysThisGood from './WhysThisGood.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const isScraping = computed(() => route.query.scraping === 'true');

const gtm = useGtm(); // Initialize GTM
const trackEvent = gtm.trackEvent.bind(gtm); // Bind trackEvent

const hydrateNow = () => {
  console.log('Hydrating');
  store.$hydrate();
}

onMounted(async () => {
  store.$hydrate();
  await store.checkLoginStatus();

  const urlParams = new URLSearchParams(window.location.search);

  // Wait for window load and next tick before setting GTM properties
  window.onload = () => {
    nextTick(() => {
      if (store.isLoggedIn) {
        console.log('User is logged in, setting GTM properties');
        window.dataLayer = window.dataLayer || [];
        console.log('Pushing user_id to dataLayer:', store.userEmail?.split('@')[0]);
        window.dataLayer.push({
          'user_id': store.userEmail?.split('@')[0] + 'AAAccc',
        });
        console.log('Pushing user info to dataLayer:', {
          user_tier: store.userTier || 'free',
          user_email: store.userEmail ? store.userEmail.replace('@', '-') : undefined
        });
        window.dataLayer.push({
          'user_tier': store.userTier || 'free',
          'user_email': store.userEmail ? store.userEmail.replace('@', '-') : undefined,
          'user_id': store.userEmail?.split('@')[0] + 'AAA-fff',
          'event': 'user_info'
        });
      }
    });
  };

  // Additional tracking for fresh login
  if (urlParams.get('action') === 'logged_in') {
    console.log('User just logged in');
    trackEvent({
      event: "login",
      category: "user_authentication",
      action: "login",
      label: "user_logged_in_successfully",
      noninteraction: false,
      user_tier: store.userTier || 'free',
      user_email: store.userEmail || '',
      user_id: store.userEmail?.split('@')[0] + 'AAAbbb',
    });
  }

  // Check if the URL contains the query parameter 'today=true'
  if (urlParams.get('today') === 'true') {
    console.log('Today is true!');
  }
});



// utils.js or within your Vue component script
function getCookie(name: string): string | null {
  let cookieValue: string | null = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.startsWith(name + "=")) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1)).replace(/\"/g, "");
        break;
      }
    }
  }
  return cookieValue;
}

function handleSignupClick() {
  trackEvent({
    event: "signup_click", // Event name
    category: "user_interaction", // Event category
    action: "click", // Event action
    label: "signup_clicked", // Event label
    noninteraction: false, // Indicates whether this event is a non-interaction event
  });
}


function handlePricingClick() {
  trackEvent({
    event: "pricing_click", // Event name
    category: "user_interaction", // Event category
    action: "click", // Event action
    label: "pricing_clicked", // Event label
    noninteraction: false, // Indicates whether this event is a non-interaction event
  });
}

function handleLoginClick() {
  trackEvent({
    event: "login_click", // Event name
    category: "user_interaction", // Event category
    action: "click", // Event action
    label: "login_clicked", // Event label
    noninteraction: false, // Indicates whether this event is a non-interaction event
  });
}

function handleHowItWorksClick() {
  trackEvent({
    event: "how_it_works_click",
    category: "user_interaction",
    action: "click",
    label: "how_it_works_clicked",
    noninteraction: false,
  });
}

const isWhyModalOpen = ref(false);

const openWhyModal = () => {
  isWhyModalOpen.value = true;
};

const closeWhyModal = () => {
  isWhyModalOpen.value = false;
};

// Add computed property for showing prominent signup
const showProminentSignup = computed(() => {
  return !store.isLoggedIn && store.searchCount >= store.allowedSearches;
});
</script>

<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Align items to the center */
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

/* 
.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
} */

.logo img {
  width: 175px;
  height: auto;
}

.logo a {
  color: #4e9f3d;
  font-size: 1.5em;
  letter-spacing: 1px;
  margin-bottom: 3px;

}

.login-link{
  font-size: 18px;
  cursor: pointer;
  color: #4e9f3d;
  text-decoration: none;
  margin-right: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narro w", Arial, sans-serif;
  letter-spacing: 1px;
}

.login-link:hover {
  text-decoration: underline;
  color: #4e9f3d;
}

.slogan {
  display: block;
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  margin-left: 8px;
  line-height: 0;
  margin-bottom: 12px;
}

.rate-limit-info {
  color: #fff;
  background-color: #333;
  padding: 2px;
  border-radius: 8px;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
  /* Align text to the left */
  display: flex;
  /* Use flexbox to align items */
  flex-direction: column;
  /* Stack items vertically */
  align-items: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  /* Align items to the start (left) of the container */
}

/* .why-link {
  display: inline-block;
  margin-top: 8px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.why-link:hover {
  background-color: #4e9f3d;
  color: white;
} */

.bigger-signup {
  /* font-size: 18px; */
  font-weight: bold;
}
</style>
